export default {
  ROLE: {
    superuser: "Администратор системы",
    admin: "Администратор компании",
    head: "Начальник",
    operational_duty: "Оперативный дежурный",
    lead_engineer: "Ведущий инженер",
    engineer: "Инженер",
    electrician: "Электрослесарь",
    electrician_underground: "Подземный электрослесарь",
    technician: "Техник",
    locksmith: "Слесарь",
    warehouseman: "Кладовщик",
    manager_safety: "Менеджер по ТБ",
  },
  MESSAGE: {
    SUCCESS: "Успешно.",
    ERROR: {
      NOT_FOUND:
        "Элемент не найден.<br />Обратитесь к администратору за дополнительными сведениями.",
      SERVER:
        "Произошла ошибка.<br />Обратитесь к администратору за дополнительными сведениями.",
      FORM: "Заполните все необходимые поля.",
      FIX: "Исправьте все необходимые поля.",
      LOGIN: "Неправильный логин или пароль.",
      BLOCK:
        "Учетная запись заблокирована. Пожалуйста, обратитесь к администратору.",
    },
  },
  WEEKDAY: {
    SHORT: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    FULL: [
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
      "Воскресенье",
    ],
  },
  EXTENSION: {
    IMG: ["jpg", "jpeg", "png", "gif", "tiff"],
  },
  MODULES: {
    WPP: {
      ROLE: {
        lead_engineer: "Ведущий инженер",
        engineer: "Инженер",
        electrician: "Электрослесарь",
        electrician_underground: "Подземный электрослесарь",
        technician: "Техник",
        locksmith: "Слесарь",
      },
      WORK_TYPE: {
        electrical_installation_work: "Работа в электроустановках",
        high_risk_work: "Работа повышенной опасности",
      },
    },
    WORK_ORDER: {
      STATUS: {
        new: "Новый",
        in_progress: "В работе",
        rescheduled: "Перенесен",
        finished: "Завершен",
        canceled: "Отменен",
      },
      STATUS_CLASS: {
        new: "blue--text",
        in_progress: "orange--text",
        rescheduled: "blue--text",
        finished: "green--text",
        canceled: "red--text",
        failed: "red--text",
      },
    },
    SPARE_PART: {
      spare_part: "Запчасть",
      component: "Узел",
      consumable: "Расходник",
    },
    DEFECT: {
      TYPE: {
        critical: "Предельное",
        unworkable: "Неработоспособное",
        workable: "Работоспособное",
      },
      STATUS: {
        fixed: "Исправлен",
        defective: "Неисправен",
      },
      STATUS_CLASS: {
        fixed: "green--text",
        defective: "red--text",
      },
      RISK_OF_FAILURE: {
        low: "Низкий",
        middle: "Средний",
        high: "Высокий",
      },
    },
    REGULATION: {
      PERIOD: {
        daily: "Ежедневно",
        weekly: "Еженедельно",
        monthly: "Ежемесячно",
      },
      PERIODICITY: {
        min30: "Каждые 30 минут",
        hour1: "Каждый 1 час",
        hour1_min30: "Каждые 1,5 часа",
        hour2: "Каждые 2 часа",
        hour2_min30: "Каждые 2,5 часа",
        hour3: "Каждые 3 часа",
        hour3_min30: "Каждые 3,5 часа",
        hour4: "Каждые 4 часа",
      },
      UNIT: [
        "Сантиметр",
        "Миллиметр",
        "Метр",
        "Оборот",
        "Штук",
        "Комплект",
        "Процент (%)",
        "Об/мин",
        "Секунд",
        "Минут",
        "Часов",
        "ОМ",
        "Ампер",
        "Вольт",
        "ВАТТ",
        "Л.С.",
        "Герц (ГЦ)",
        "Давление (Pa)",
        "Бар",
        "Атмосфер",
        "Кг*С",
        "N*m",
        "мм2",
        "см2",
        "м2",
        "литр",
        "мм3",
        "см3",
        "дм3",
        "м3",
        "Килограмм",
        "Тонн",
        "Грамм",
      ],
      STATUS: {
        new: "Новый",
        in_progress: "В работе",
        done: "Пройден",
      },
    },
    AGGREGATE_JOURNAL: {
      SOURCE: {
        defect: "Дефект",
        work_order: "Наряд",
        regulation: "Регламент",
      },
    },
    MONITORING: {
      TYPE: [
        {
          id: "skip",
          title: "Количество скипов",
          order: 0,
        },
        {
          id: "ore",
          title: "Отсутствие руды",
          order: 1,
        },
        {
          id: "bunker",
          title: "Бункера заполнены",
          order: 2,
        },
        {
          id: "jam",
          title: "Пробка в скипу, рудоспуске, дозаторе",
          order: 3,
        },
        {
          id: "delay",
          title: "Задержка, связанная со спуском/подъемом клети",
          order: 4,
        },
        {
          id: "shift_change",
          title: "Пересменка, проверка защит",
          order: 5,
        },
        {
          id: "defect",
          title: "Неисправность оборудования ШПМ",
          order: 6,
        },
        {
          id: "downtime",
          title:
            "Простои связанные с нарушением снабжения электро и пневмоэнергией, состоянием ствола",
          order: 7,
        },
        {
          id: "failure",
          title:
            "Отказ оборудования в результате его неправильной эксплуатации",
          order: 8,
        },
        {
          id: "repair",
          title: "Время ремонта",
          order: 9,
        },
      ],
    },
    SAFETY: {
      VIOLATION: {
        STATUS: {
          new: "Новый",
          finished: "Исправлено",
        },
        STATUS_CLASS: {
          new: "blue--text",
          finished: "green--text",
        },
      },
    },
    REQUEST: {
      TICKET: {
        TYPE: {
          lpp: "ЛПП",
          sp: "СП",
          water: "Вода",
          milk: "Молоко",
          increased_sp: "Усиленный СП",
        },
      },
      GOOD: {
        WORK_TYPE: ["СОСО", "СОКУ", "СОКОМ", "ГН", "ПГН"],
        COST_ITEM: ["Инструкционный фонд", "Централизованный фонд"],
      },
    },
    REPORT: {
      STATUS: {
        in_progress: "В работе",
        finished: "Завершен",
      },
      STATUS_CLASS: {
        in_progress: "orange--text",
        finished: "green--text",
      },
      OPERATION: {
        create: "Создание",
        update: "Редактирование",
        finish: "Завершение",
        cancel: "Аннулирование",
      },
    },
    REPORT2: {
      STATUS: {
        new: { text: "Новый", value: "new", class: "blue--text" },
        in_progress: {
          text: "В работе",
          value: "in_progress",
          class: "yellow--text",
        },
        rework: { text: "Доработка", value: "rework", class: "red--text" },
        completed: {
          text: "Выполнен",
          value: "completed",
          class: "green--text",
        },
      },
    },
  },
};
